<template>
	<div class="task-kanban" v-if="getPermission('task:view')">
		<div class="kanban-view-list">
			<v-flex
				v-for="(row, index) in taskData"
				:key="index"
				class="kanban-innerlist"
				style="width: calc(33% - 20px)"
			>
				<div class="list-title" :class="`${row.status.status_color} lighten-4 list-title`">
					<div class="d-flex">
						<div class="fw-600 title-text">{{ row.status.text }}</div>
						<v-spacer></v-spacer>
						<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
					</div>
					<div class="mt-1" v-if="row.status && row.status.status_color">
						<v-chip class="mb-1 mr-1" label :color="`${row.status.status_color} white--text`" x-small>
							{{ row.list.length }} Task
						</v-chip>
					</div>
				</div>
				<Container
					group-name="leadkanbanlist"
					v-on:drop="onDrop(index, row.list, $event, row.status.value)"
					:get-child-payload="(e) => getChildPayload(row.list, index, e)"
					:drop-placeholder="dropPlaceholderOptions"
					class="overflow-list-item"
					:id="!row.list.length ? 'blank-list' : ''"
				>
					<Draggable v-model="row.status.value" v-for="(data, index) in row.list" :key="index" class="">
						<div
							:class="`${row.status.status_color} lighten-5 list-items`"
							v-on:click="pushToRoute(data.id)"
						>
							<template>
								<v-tooltip
									:color="data.priority_color ? data.priority_color : null"
									top
									:content-class="` ${getActiveColor(data.priority)} custom-top-tooltip`"
								>
									<template v-slot:activator="{ on, attrs }">
										<div :class="`labeltag ${getActiveColor(data.priority)}`" v-on="on" v-bind="attrs"></div>
									</template>
									<span>{{ data.priority }}</span>
								</v-tooltip>
							</template>
							<div class="d-flex">
								<div class="ellipsis fw-500 mr-2 w-80">
									<span class="fw-600 pr-2">Task # : </span>
									<template v-if="data && data.barcode">
										<span class="fw-500">{{ data.barcode }}</span>
									</template>
									<template v-else>no barcode</template>
								</div>
								<div class="ellipsis fw-500 text-end w-20">
									<template v-if="data && data.related_to">
										<span class="fw-500">
											<v-chip
												class="ml-2 text-capitalize"
												color="red white--text"
												x-small
												label
												v-if="data && data.related_to"
												>{{ data.related_to.replace("-", " ") }}</v-chip
											></span
										>
									</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Title : </span>
									<template v-if="data && data.title">
										<span class="fw-500">{{ data.title }}</span>
									</template>
									<em class="text-muted" v-else> no title </em>
								</div>
							</div>
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_barcode && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Lead # : </span>
									<template v-if="data && data.lead_barcode">
										<span class="fw-500">{{ data.lead_barcode }}</span>
									</template>
								</div>
							</div> -->
							<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Company Name : </span>
									<template v-if="data && data.related">
										<span class="fw-500">{{ data.related }}</span>
									</template>
									<em class="text-muted" v-else> no company name </em>
								</div>
							</div>
							<!-- <div
								class="ellipsis pr-6"
								v-if="data && data.lead_customer_name && data.related_to == 'lead'"
							>
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Person Incharge : </span>
									<template v-if="data && data.lead_customer_name">
										<span class="fw-500">{{ data.lead_customer_name }}</span>
									</template>
									<em class="text-muted" v-else> no person incharge </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Lead Date : </span>
									<template v-if="data && data.lead_date">
										<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
									</template>
									<em class="text-muted" v-else> no lead date </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Date : </span>
									<template v-if="data && data.start_date">
										<span class="fw-500">{{ formatDate(data.start_date) }}</span>
									</template>
									<em class="text-muted" v-else>no date</em>
								</div>
							</div> -->
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Due Date : </span>
									<template v-if="data && data.due_date">
										<span class="fw-500">{{ formatDate(data.due_date) }}</span>
									</template>
									<em class="text-muted" v-else>no due date</em>
								</div>
							</div>
							<div class="d-flex">
								<div class="ellipsis fw-500 mr-2 w-80">
									<span class="fw-600 pr-2">Due Date : </span>
									<template v-if="data && data.due_date">
										<span class="fw-500">{{ formatDate(data.due_date) }}</span>
									</template>
									<em class="text-muted" v-else>no due date</em>
								</div>
								<div class="ellipsis fw-500 text-end w-20">
									<v-icon color="blue" v-on:click.prevent.stop="openChecklist(data)"
										>mdi-playlist-check</v-icon
									>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Attendees : </span>
									<template
										v-if="
											data && data.attendies[0] && data.attendies[0].display_name && data.attendies.length
										"
									>
										<span class="fw-500"
											>({{ data.attendies[0].display_name }})
											<template v-if="data.attendies.length > 1">
												+{{ data.attendies.length - 1 }} More
											</template></span
										>
									</template>
									<em class="text-muted" v-else>no attendees</em>
								</div>
							</div>
							<!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Reminder :</span>
									<template v-if="data.reminder && data.reminder.start_date && data.reminder.end_date">
										{{ formatDateTime(data.reminder.start_date) }}
										</template
									>
								
									<em class="text-muted" v-else> no reminder</em>
								</div>
							</div> -->

							<v-tooltip top content-class="custom-top-tooltip" v-if="!data.reminder">
								<template v-slot:activator="{ on, attrs }">
									<div v-on:click.stop="" class="labeltag editField cursor-pointer" v-on="on" v-bind="attrs">
										<v-icon color="blue" v-on:click="openReminder(data)">mdi-alarm</v-icon>
									</div>
								</template>

								<template> Add Reminder </template>
							</v-tooltip>
							<!-- <v-checkbox v-on:click.stop="" hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
						</div>
					</Draggable>
				</Container>
			</v-flex>
			<template>
				<Dialog :dialog="reminderDialog">
					<template v-slot:title>Task {{ taskBarcode }} Reminder</template>
					<template v-slot:body>
						<div>
							<div class="drawer-content pt-5 px-5">
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Title</label>
									</div>
									<v-text-field
										depressed
										hide-details
										outlined
										placeholder="Title"
										v-model="reminder.title"
										:disabled="pageLoading"
										:loading="pageLoading"
									></v-text-field>
								</div>
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Reminder Type</label>
									</div>
									<AutoCompleteInput
										dense
										id="reminder-type"
										hide-details
										placeholder="Select Reminder Type"
										:items="reminder_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="reminder.type"
										item-text="text"
										item-value="value"
										v-on:change="get_reminder_values()"
									>
									</AutoCompleteInput>
								</div>
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500 required"
											><!-- <template v-if="reminder.type != 1">Start </template> -->Start Date</label
										>
									</div>
									<DateTimePicker
										dense
										id="reminder-start-date"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="reminder.start_date"
										:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Start Date...'"
										v-on:change="get_reminder_values()"
										:rules="[
											vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Start Date'),
										]"
									></DateTimePicker>
									<!-- <DatePicker
										dense
										id="reminder-start-date"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="reminder.start_date"
										:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Start Date...'"
										v-on:change="get_reminder_values()"
										:rules="[
											vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Start Date'),
										]"
									></DatePicker> -->
								</div>
								<div class="mb-3" v-if="reminder.type != 1">
									<div class="d-flex pt-1">
										<label class="fw-500">Frequency</label>
									</div>
									<TextInput
										v-if="false"
										dense
										id="reminder-frequency"
										hide-details
										type="number"
										placeholder="Enter Frequency..."
										:disabled="pageLoading"
										:loading="pageLoading"
										class="mt-0"
										v-model="reminder.frequency"
										v-on:keyup="get_reminder_values()"
									></TextInput>
									<v-text-field
										type="Number"
										v-mask="'####'"
										depressed
										hide-details
										outlined
										placeholder="Enter Frequency..."
										v-model="reminder.frequency"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-on:keyup="get_reminder_values()"
										@wheel="$event.target.blur()"
									></v-text-field>
								</div>
								<div class="mb-3" v-if="reminder.type != 1">
									<div class="d-flex pt-1">
										<label class="fw-500">End Date</label>
									</div>
									<!-- <DatePicker
										dense
										id="reminder-end-date"
										hide-details
										placeholder="Select End Date..."
										:disabled="pageLoading"
										:loading="pageLoading"
										readonly
										v-model="reminder.end_date"
									>
									</DatePicker> -->
									<DateTimePicker
										dense
										id="reminder-end-date"
										hide-details
										placeholder="Select End Date..."
										:disabled="pageLoading"
										:loading="pageLoading"
										readonly
										v-model="reminder.end_date"
									></DateTimePicker>
								</div>
								<div class="mb-3">
									<v-radio-group row hide-details class="mt-0" v-model="reminder.callType">
										<v-radio color="green" label="Call" value="call"></v-radio>
										<v-radio color="red" label="Email" value="email"></v-radio>
									</v-radio-group>
								</div>
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Attendees</label>
									</div>
									<v-autocomplete
										:items="userList"
										item-text="display_name"
										item-value="id"
										depressed
										hide-details
										placeholder="Select Attendees"
										outlined
										hide-selected
										multiple
										color="blue darken-4"
										small-chips
										deletable-chips
										v-model="reminder.attendees"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
										<template v-slot:selection="data">
											<v-chip
												small
												v-bind="data.attrs"
												:input-value="data.selected"
												close
												@click="data.select"
												@click:close="remove(data.item)"
											>
												<v-avatar left>
													<!-- <v-img :src="data.item.profile_img"></v-img> -->
													<img v-if="data.item.profile_img" :src="data.item.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												{{ data.item.display_name }}
											</v-chip>
										</template>
										<template v-slot:item="data">
											<template>
												<v-list-item-avatar>
													<!-- <img :src="data.item.profile_img" /> -->
													<img v-if="data.item.profile_img" :src="data.item.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
												</v-list-item-content>
											</template>
										</template>
									</v-autocomplete>
								</div>
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Description</label>
									</div>
									<ckeditor
										:editor="editor"
										v-model="reminder.description"
										:config="editorConfig"
									></ckeditor>
								</div>
								<!-- <template v-if="reminder.id > 0">
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500">Remark</label>
										</div>
										<div>
											<div class="inbox custom-chat">
												<span class="divider-by-day"
													><span v-if="reminder && reminder.added_at">{{ reminder.added_at }}</span></span
												>

												<div class="mine">
													<div class="message" v-if="reminder && reminder.description">
														<div v-html="reminder.description" style="margin-bottom: 0px"></div>
														<div style="text-align: end; color: #917a7a; font-size: 11px">
															{{ reminder.added_atFormated }}
														</div>
													</div>
												</div>
												<template v-for="(data, index) in reminderMsg">
													<div :key="index">
														<span class="divider-by-day" v-if="data && data.label"
															><span>{{ data.label }}</span></span
														>
														<div v-if="data.user_name">
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<v-avatar width="30px" min-width="30px" height="30px"
																		class="pa-0 lighten-3 white--text mr-1"
																		v-bind="attrs" v-on="on" left>
																		<img v-if="data.user_img" :src="data.user_img"
																			:alt="data.name" />
																		<img v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />

																	</v-avatar>
																</template>
																<span>{{ data.user_name }}</span>
															</v-tooltip>
															
														</div>
														<div :class="data.color">
															<div class="message" v-if="data">
																<template v-if="data && data.remark">{{ data.remark }}<br /></template>

																<template v-else>
																	<template
																		v-if="
																			getFileExtension(data.attachment) == 'jpg' ||
																			getFileExtension(data.attachment) == 'jpeg' ||
																			getFileExtension(data.attachment) == 'png'  ||
																			getFileExtension(data.attachment) == 'jfif'
																		"
																	>
																		<v-img
																			:src="data.attachment"
																			contain
																			class="white lighten-2"
																			:max-height="150"
																			:max-width="150"
																			v-on:click="FileDownload(data.attachment)"
																		>
																		</v-img>
																	</template>
																	<template v-if="getFileExtension(data.attachment) == 'pdf'">
																		<inline-svg
																			:src="$assetURL('media/mime/pdf.svg')"
																			v-on:click="FileDownload(data.attachment)"
																		/>
																	</template>
																	<template v-if="getFileExtension(data.attachment) == 'docx' || getFileExtension(data.attachment) == 'doc'">
																		<inline-svg
																			:src="$assetURL('media/mime/doc.svg')"
																			v-on:click="FileDownload(data.attachment)"
																		/>
																	</template>
																	<template v-if="getFileExtension(data.attachment) == 'xls' || getFileExtension(data.attachment) == 'xlsx'">
																		<inline-svg
																			:src="$assetURL('media/mime/xls.svg')"
																			v-on:click="FileDownload(data.attachment)"
																		/>
																	</template>
																</template>
																<div style="text-align: end; color: #917a7a; font-size: 11px">
																	{{ data.added_at }}
																</div>
															</div>
														</div>
													</div>
												</template>
											</div>
											<div class="textarea">
												<v-textarea
													style="border: 1px solid"
													prepend-inner-icon="mdi-attachment"
													@click:prepend-inner="uploadDialog()"
													hide-details
													class="ma-0"
													v-model.trim="reminderChat.reminder_remark"
													outlined
													rows="2"
													placeholder="Messages"
													v-on:keypress.enter="onSubmitReminderChat()"
												>
												</v-textarea>
												<v-icon
													class="send-btn cursor-pointer"
													v-on:click="onSubmitReminderChat()"
													v-if="reminderChat.reminder_remark"
													>mdi-send</v-icon
												>
											</div>
										</div>
									</div>
								</template> -->
							</div>
						</div>
					</template>
					<template v-slot:action>
						<v-btn
							class="white--text"
							depressed
							color="blue darken-4"
							tile
							v-on:click="update_or_create()"
						>
							Save
						</v-btn>
						<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog('reminder')">
							Close
						</v-btn>
					</template>
				</Dialog>
			</template>
			<template>
				<Dialog :dialog="checklistDialog">
					<template v-slot:title>Task {{ taskBarcode }} Checklist</template>
					<template v-slot:body>
						<div>
							<ChecklistListing
								v-if="checklistDialog"
								:task-id="task_id"
								:task-type="'task'"
								:db-notes="checklists"
								v-on:updateTask="getTaskChecklist"
							></ChecklistListing>
						</div>
					</template>
					<template v-slot:action>
						<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog('checklist')">
							Close
						</v-btn>
					</template>
				</Dialog>
			</template>
			<add-dialog
				:document-dialog="openUploadModel"
				v-on:close="openUploadModel = false"
				v-on:success="getReminderRemark(reminder_id)"
				:related-id="reminder_id"
				:parent-id="task_id"
				:chat="0"
				:related-type="'task-reminder-remark'"
			></add-dialog>
		</div>
	</div>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
</style>
<script>
import ChecklistListing from "@/view/module/components/checklist-listing";
import { Container, Draggable } from "vue-smooth-dnd";
//import ProjectMember from "@/view/module/project/ProjectMember";
import { QUERY, PATCH, POST, GET, PUT } from "@/core/services/store/request.module";
import { filter, cloneDeep } from "lodash";
//import { TaskEventBus } from "@/core/lib/meeting.form.lib";
import { map, last } from "lodash";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
/* import DatePicker from "@/view/components/DatePicker"; */
import DateTimePicker from "@/view/components/DateTimePicker";

import Dialog from "@/view/components/Dialog";
import MomentJS from "moment-timezone";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import { TaskEventBus } from "@/core/lib/task.form.lib";

export default {
	name: "TaskKanban",
	components: {
		Container,
		Draggable,
		Dialog,
		/* DatePicker, */
		DateTimePicker,
		ChecklistListing,
		AutoCompleteInput,
		TextInput,
		AddDialog,
		//ProjectMember,
	},
	data() {
		return {
			openUploadModel: false,
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			manageNote: false,
			viewType: true,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			labelItems: ["High", "Medium", "Low"],
			filter_label: "High",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			statusvalue: 0,
			taskId: 0,
			taskData: [],
			pageLoading: false,
			taskBarcode: null,
			dragstatus: null,
			userList: [],
			related_type: "task",
			timeList: [],
			reminderMsg: [],
			editor: ClassicEditor,
			editorData: "Description",
			editorConfig: {
				// The configuration of the editor.
			},
			checklistDialog: false,
			task_id: 0,
			reminder_id: 0,
			reminderDialog: false,
			reminderChat: {
				reminder_remark: null,
				reminder_id: 0,
				related_type: "task-reminder-remark",
			},
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			],
			reminder: {
				title: null,
				start_date: this.formatDateTimeRaw(new Date()),
				end_date: null,
				type: 1,
				frequency: 1,
				related_to: null,
				callType: "call",
				related_id: 0,
				description: null,
				attendees: [],
				added_atFormated: null,
			},
			checklists: [],
			/* reminder: {
				id:0,
				title: null,
				date: null,
				time: null,
				related_to: null,
				related_id: 0,
				description: null,
				attendees: [],
				added_atFormated:null,
			}, */
		};
	},
	watch: {
		$route: {
			handler() {
				const { query } = this.$route;
				this.gettaskListing(query);
			},
		},
	},
	methods: {
		closeDialog(value) {
			if (value == "reminder") {
				this.reminderDialog = false;

				this.gettaskListing();
			} else {
				this.remarkDialog = false;
			}
			if (value == "checklist") {
				this.checklistDialog = false;
			}
		},
		getActiveColor(data) {
			if (data == "low") {
				return "cyan";
			}
			if (data == "medium") {
				return "green";
			}
			if (data == "high") {
				return "orange";
			}
			if (data == "urgent") {
				return "blue";
			}
		},
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		FileDownload(data) {
			window.open(data, "_blank");
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		get_reminder_values() {
			let reminderParseDate = MomentJS(this.reminder.start_date);
			/* let test = MomentJS(reminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */

			for (var i = 0; i < this.reminder.frequency; i++) {
				if (this.reminder.type == 6) {
					/* Half Yearly */
					reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
				}

				if (this.reminder.type == 5) {
					/* Quarterly */
					reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
				}

				if (this.reminder.type == 4) {
					/* Monthly */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
				}

				if (this.reminder.type == 3) {
					/* Weekly */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}

				if (this.reminder.type == 2) {
					/* Daily */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
				}

				if (this.reminder.type == 1) {
					/* One Time */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}
			}

			return (this.reminder.end_date = reminderParseDate.format("YYYY-MM-DD"));
		},
		gettaskListing(query) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `task`,
					data: {
						status: "all",
						withOutPage: true,
						...query,
					},
				})
				.then((data) => {
					const output = [];
					for (let i = 0; i < data.status.length; i++) {
						if (data.status[i].value == "all") {
							continue;
						}
						output.push({
							status: data.status[i],
							list: filter(data.tbody, { status: data.status[i].text }),
						});
					}
					this.taskData = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			if (this.reminder_id > 0) {
				this.updateReminderData();
			} else {
				this.saveReminderData();
			}
		},
		getTaskChecklist() {
			this.$store
				.dispatch(GET, { url: `task/${this.task_id}/checklists` })
				.then((data) => {
					this.checklists = data;
					console.log(this.checklists, "this.checklists");
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		onSubmitReminderChat() {
			this.reminderChat.reminder_id = this.reminder_id;
			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `reminder-remark/${this.task_id}`, data: this.reminderChat })
				.then(() => {
					this.reminderChat.reminder_remark = "";
					this.getReminderRemark(this.reminderChat.reminder_id);
					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		saveReminderData() {
			this.pageLoading = true;
			this.reminder.related_id = this.task_id;
			this.reminder.related_to = "task";
			this.$store
				.dispatch(POST, { url: `reminder`, data: this.reminder })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder added successfully." },
					]);
					this.reminderDialog = false;
					this.gettaskListing();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateReminderData() {
			this.reminder.related_id = this.task_id;
			this.reminder.related_to = "task";
			this.pageLoading = true;
			this.$store
				.dispatch(PUT, {
					url: `reminder/${this.reminder_id}`,
					data: this.reminder,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder updated successfully." },
						(this.reminderDialog = false),
						this.gettaskListing(),
					]);

					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pushToRoute(id) {
			let filterQuery = this.$route.query;
			this.$router.push({
				name: "task-detail",
				params: { id },
				query: { ...filterQuery, t: new Date().getTime(), viewType: true },
			});
		},
		openReminder(data) {
			this.taskBarcode = data.barcode;
			this.default_remakr = data.remark;
			this.default_date = MomentJS(data.added_at);
			this.task_id = data.id ? data.id : 0;
			if (data && data.reminder) {
				this.reminder_id = data.reminder.id ? data.reminder.id : 0;
			}
			if (this.reminder_id) {
				this.getReminder();
			}

			this.reminderDialog = true;
		},
		openChecklist(data) {
			this.taskBarcode = data.barcode;
			this.task_id = data.id ? data.id : 0;
			this.getTaskChecklist();
			this.checklistDialog = true;
		},
		getReminderRemark(reminder_id) {
			this.$store
				.dispatch(QUERY, {
					url: `reminder-remark/${this.task_id}/${reminder_id}`,
					data: { related_type: "task-reminder-remark" },
				})
				.then((data) => {
					this.reminderMsg = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getReminder() {
			this.reminder = {
				id: 0,
				title: null,
				start_date: null,
				end_date: null,
				type: 1,
				frequency: 1,
				related_to: null,
				related_id: 0,
				description: null,
				attendees: [],
				added_atFormated: null,
			};
			if (this.reminder_id > 0) {
				this.$store
					.dispatch(GET, {
						url: `single-reminder/${this.reminder_id}/${this.related_type}`,
					})
					.then((data) => {
						this.reminder.id = data.reminder.id;
						this.reminder.title = data.reminder.title;
						this.reminder.start_date = MomentJS(data.reminder.start_date, "YYYY-MM-DD HH:II:SS").format(
							"YYYY-MM-DD HH:II:SS"
						);
						(this.reminder.end_date = data.reminder.end_date
							? MomentJS(data.reminder.end_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
							: null),
							(this.reminder.type = data.reminder.type);
						this.reminder.frequency = data.reminder.frequency ? data.reminder.frequency : 1;
						this.reminder.related_to = data.reminder.related_to;
						this.reminder.related_id = data.reminder.related_id;
						this.reminder.description = data.reminder.description;
						this.reminder.added_at = MomentJS(data.reminder.added_at).format("LLLL");
						this.reminder.added_atFormated = MomentJS(data.reminder.added_at).format("hh:mm A");
						this.reminder.attendees = map(data.attendie[0].attendies, "id");
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},

		onDrop(index, collection, dropResult, dropStatus) {
			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}
			if (this.dragstatus == "Completed") {
				/* this.statusDialog = true; */
				this.taskData[index].list = this.applyDrag(collection, dropResult);
				this.gettaskListing();
				return false;
			}
			/* for (let i = 0; i < payload.list.length; i++) {
				this.taskId = payload.list[i].id;
				this.statusvalue = payload.status.value;
			} */
			if (addedIndex != null) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `task/${this.taskId}/${this.statusdata}` })
					.then(() => {
						this.gettaskListing();
					})
					.catch((error) => {
						this.logError(error);
						this.gettaskListing();
					})
					.finally(() => {
						this.update_loading = false;
					});
			}

			this.taskData[index].list = this.applyDrag(collection, dropResult);
		},
		getChildPayload(row, index, event) {
			this.taskId = row[event].id;
			this.dragstatus = row[event].status;
			return this.taskData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result;
		},
		getReminderSetting() {
			this.$store
				.dispatch(GET, { url: `meeting-setting` })
				.then((data) => {
					this.timeList = data.time;
					this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	mounted() {
		this.getReminderSetting();
		this.reminder.attendees = [this.userId];
		this.gettaskListing();
		this.viewType = this.$route.query.viewType;
		TaskEventBus.$on("refresh", () => {
			this.gettaskListing();
		});
	},
};
</script>
